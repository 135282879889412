import Vue from 'vue'
import App from './App.vue'
import echarts from 'echarts';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import 'babel-polyfill';//放置顶部
import Es6Promise from 'es6-promise'//放置顶部
Es6Promise.polyfill()
import { timeShow, validateFieldFun, returnTime, returnTimestamp, Base64,calculateTimeDifference } from './utils/publicJS'
Vue.prototype.$returnTimestamp = returnTimestamp
Vue.prototype.$calculateTimeDifference = calculateTimeDifference
Vue.prototype.$returnTime = returnTime
// 单行验证
Vue.prototype.$validateFieldFun = validateFieldFun
Vue.prototype.$timeShow = timeShow
Vue.prototype.$Base64 = Base64
// 根据屏幕宽度设置 HTML 字体大小
function setHtmlFontSize() {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const baseSize = 18; // 基准字体大小，可根据需求调整
  const scale = screenWidth / 375; // 假设设计稿宽度为 375px，可根据实际情况调整

  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
Vue.prototype.$encodeQueryParams = function encodeQueryParams(params) {
  let encodedParams = Object.keys(params).map(key => {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(params[key]);
    return `${encodedKey}=${encodedValue}`;
  });

  return encodedParams.join('&');
}
Vue.prototype.$decodeQueryParams = function decodeQueryParams(encodedParams) {
  let decodedParams = decodeURIComponent(encodedParams);
  let params = {};
  decodedParams.split('&').forEach(param => {
    let [key, value] = param.split('=');
    params[key] = value;
  });
  return params;
}
// 监听窗口大小变化，动态设置 HTML 字体大小
window.addEventListener('resize', setHtmlFontSize);
import MorePicker from './components/morepicker/index.vue'
Vue.component('MorePicker', MorePicker);

Vue.use(router);
Vue.prototype.$echarts = echarts
import axios from 'axios'
Vue.prototype.$http = axios
Vue.config.productionTip = false







new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
// 初始化时设置一次 HTML 字体大小
setHtmlFontSize();
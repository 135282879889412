<template>
  <div class="warrper">
    <div class="content">
      <router-view></router-view>
    </div>
    <div class="down">
      <van-tabbar
        style="height: 3.125rem"
        v-model="activeTab"
        @change="handleTabClick"
      >
        <van-tabbar-item name="/app/home">
          <span>首页</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/activity_fill1.png')
                  : require('@/assets/activity_fill0.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="/app/myView">
          <span>我的</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/people_fill1.png')
                  : require('@/assets/people_fill0.png')
              "
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "layouts",
  data() {
    return { activeTab: "/app/home" };
  },
  watch: {
    $route: {
      handler(route) {
        this.activeTab = this.$route.path;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleTabClick(tab) {
      this.activeTab = tab;
      this.$router.push(tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.warrper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .up {
    flex-shrink: 0;
  }
  .content {
    flex-grow: 1;
    overflow: auto;
  }
  .down {
    flex-shrink: 0;
    height: 3.125rem;
    // height: 3.75rem;
  }
}
</style>
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import DevicePixelRatio from './utils/devicePixelRatio';

export default {
  name: "App",
  data() {
    return {
      number: 0,
    };
  },
  mounted() {
    // window.devicePixelRatio就是当前浏览器和系统缩放过后的比例，我只需要反向缩放body即可。
    // document.body.style.zoom = 1 / window.devicePixelRatio;
  },
  created() {
    // new DevicePixelRatio().init();
  },
};
</script>
<style >
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei";
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
  /* background: #07072e; */
}
.van-nav-bar__content {
  height: 3.0625rem !important;
}
.van-nav-bar__left,
.van-nav-bar__right {
  font-size: 1rem !important;
}
.van-nav-bar__arrow,
.van-notify,
.van-button--normal,
.van-button {
  font-size: 0.875rem !important;
}
.van-button--normal {
  padding: 0 0.9375rem !important;
}
.van-nav-bar__title {
  font-size: 1rem !important;
}
.van-nav-bar {
  line-height: normal !important;
}
.van-dialog {
  width: 20rem !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
}
.van-dialog__header {
  padding-top: 1.625rem !important;

  line-height: 1.5rem !important;
}
.van-dialog__cancel,
.van-dialog__confirm {
  height: 3rem !important;
}
.van-button--small {
  height: 1.875rem !important;
  padding: 0 0.625rem !important;
}
.van-field--disabled .van-field__label {
  color: #646566 !important;
}
.van-field__control:disabled {
  color: #323233 !important;
  -webkit-text-fill-color: #323233 !important;
}
.van-cell {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 0.5rem 0.875rem !important;
}
.myloading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .van-field__control:disabled {
  color: #c8c9cc !important;
  -webkit-text-fill-color: #c8c9cc !important;
} */
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import layouts from '../layouts/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/app/login',  // 修改路径为/app/login
    name: '登录',
    component: () => import('@/views/login/index'),
    meta: {}
  },
  {
    path: '/',
    component: layouts,
    redirect: '/app/home',
    meta: {},
    children: [
      {
        path: '/app/home',
        name: '首页',
        component: () => import('@/views/home/index'),
        meta: {}
      }
    ]
  },
  {
    path: '/app/myView',
    component: layouts,
    redirect: '/app/myView',
    meta: {},
    children: [
      {
        path: '/app/myView',
        name: '我的',  // 子路由不需要再写/app，因为已经在父级设置了
        component: () => import('@/views/myView/index'),
        meta: {}
      }
    ]
  },
  {
    path: '/app/list',
    name: '列表',
    component: () => import('@/views/list/index'),
    meta: {}
  },
  {
    path: '/app/addeditdetail',
    name: '操作',
    component: () => import('@/views/addeditdetail/index'),
    meta: {}
  },
  {
    path: '/app/Digitalpackaging/ProductionSchedulingAdjustment',
    name: '排产调整',
    component: () => import('@/views/baozhuang/paichantiaozheng/index'),
    meta: {}
  },
  // {
  //   path: '/app/Digitalpackaging/ProductionSchedulingAdjustmentDetail',
  //   name: '排产调整详情',
  //   component: () => import('@/views/baozhuang/paichantiaozheng/Detail'),
  //   meta: {}
  // },
  {
    path: '/app/system/Digitalpackaging/Packagingprocessdefinition',
    name: '包装线',
    component: () => import('@/views/baozhuang/gongxudingyi/index'),
    meta: {}
  },
  // {
  //   path: '/app/baozhuang/gongxudingyi/AddUpDetail',
  //   name: '包装线操作',
  //   component: () => import('@/views/baozhuang/gongxudingyi/AddUpDetail'),
  //   meta: {}
  // },
  // {
  //   path: '/app/gongxu',
  //   name: '包装线工序',
  //   component: () => import('@/views/baozhuang/gongxudingyi/gongxu'),
  //   meta: {}
  // },
  // {
  //   path: '/app/gongxuAddUpDetail',
  //   name: '包装线工序操作',
  //   component: () => import('@/views/baozhuang/gongxudingyi/gongxuAddUpDetail'),
  //   meta: {}
  // },
  {
    path: '/app/system/Digitalpackaging/PackagingDaily',
    name: '产量日报',
    component: () => import('@/views/baozhuang/chanliangribao/index'),
    meta: {}
  },
  {
    path: '/app/system/Digitalpackaging/PackagingDailyPersonnel',
    name: '人民日报',
    component: () => import('@/views/baozhuang/chanliangribao/index'),
    meta: {}
  },
  {
    path: '/app/system/Digitalpackaging/PackagingDailyConsumables',
    name: '耗材日报',
    component: () => import('@/views/baozhuang/chanliangribao/index'),
    meta: {}
  },

];

const router = new VueRouter({
  mode: 'hash', //'hash' 'history'
  base: '',  // 设置基本路径为''  '/'
  routes
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/app/login' && localStorage.getItem('token')) {
    next('/app/home'); // 如果用户已登录且当前页面是登录页，则重定向到首页
  } else if (to.path !== '/app/login' && !localStorage.getItem('token')) {
    next('/app/login'); // 如果用户未登录且当前页面不是登录页，则重定向到登录页
  } else {
    next(); // 其他情况正常跳转
  }
});

export default router;

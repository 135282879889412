// 系统当前日期
export function timeShow(number) {
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate();
  var hour = date.getHours()
  var minutes = date.getMinutes()
  var seconds = date.getSeconds()
  // var hm = date.getMilliseconds()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (day >= 0 && day <= 9) {
    day = '0' + day
  }
  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  if (number) {
    return year + '-' + month + '-' + day

  } else {
    // return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
    return hour + ':' + minutes + ':' + seconds

  }
}

// 计算两个日期时间相差多少小时分钟秒yyyy-mm-dd HH:MM:ss
export function calculateTimeDifference(startDate, endDate) {
  // 解析日期时间字符串为日期对象
  const start = parseDateString(startDate);
  const end = parseDateString(endDate);

  // 计算时间差（单位：毫秒）
  const timeDifference = Math.abs(end - start);

  // 将时间差转换为小时、分钟和秒
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
}
// el-form el-table 单行验证
export function validateFieldFun(form, index) {
  let result = true
  for (let item of this.$refs[form].fields) {
    if (item.prop.split('.')[1] == index) {
      this.$refs[form].validateField(item.prop, (err) => {
        if (err != '') {
          result = false
        }
      })
    }
    // if(!result) break
  }
  return result
}
// 根据时间戳返回时间
export function returnTime(timestamp, number) {

  const date = new Date(Number(timestamp));


  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从0开始计算，需加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  if (number) {
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
  } else {
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }
}
// 根据时间戳返回时间
export function returnTimestamp(dateString) {

  var date = new Date(dateString);

  // 获取时间戳
  if (dateString.length > 11) {
    return date.getTime();
  } else {
    return date.getTime() - (8 * 60 * 60 * 1000);
  }

}

export const Base64 = {
  //加密
  encode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  },
  //解密
  decode(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
 
